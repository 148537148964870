//=====================
// SIMPLE ACCORDION
//======================
$('.js-accordion-trigger').bind('click', function(e){
  jQuery(this).parent().find('.c-news-list__accordion-submenu').slideToggle('fast');
  jQuery(this).parent().toggleClass('is-expanded');
  e.preventDefault();
});

//================
// Careers Page
//================
$('.js-accordion-careers').bind('click', function(e){
  jQuery(this).parents(".s-careers__list-item--button").find('.s-careers__accordion-submenu').slideToggle('slow');
  // jQuery(this).parents().toggleClass('is-expanded');
  e.preventDefault();
});
