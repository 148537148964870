/* globals require */
/* jshint node:true */

'use strict';

$(document).ready(function () {
  // `require` your modules here
  var sliders = require('./modules/sliders');
  require('./modules/shrink-header');
  require('./modules/flaunt');
  require('./modules/custom-accordion');
  require('./modules/custom-modal');

  sliders.init();

  require('./modules/foundation');

});
