/*jshint node:true*/

'use strict';

/**
 * Controller for sliders on the site
 * @type {Object}
 */
var SliderController = {
  mainSlider: $('.js-main-slider'),
  subSlider: $('.js-main-slider-sub'),
  singleSlider: $('.js-slider-single'),
  threeSlider: $('.js-slider-three'),

  /**
   * Initialize the sliders
   */
  init: function() {
    var _ = this;
    if (this.mainSlider.length) {
      this.mainSlider.slick({
        'dots': false,
        'mobileFirst': true,
        'buttons': false,
        'arrows':false,
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'asNavFor:': '.js-main-slider-sub'
      });


      var $next = $('.js-slider-next');
      var $prev = $('.js-slider-previous');

      $next.on('click', function (e) {
          e.preventDefault();
          _.mainSlider.slick('next');
        });

      $prev.on('click', function (e) {
        e.preventDefault();
        _.mainSlider.slick('prev');
      });
    }

    if (this.subSlider.length) {
      this.subSlider.slick({
        'dots': false,
        'mobileFirst': true,
        'buttons': false,
        'arrows': false,
        'slidesToShow': 3,
        'slidesToScroll': 1,
        'asNavFor:': '.js-main-slider',
        'focusOnSelect': true,
        'responsive': [
        {
          'breakpoint': 1024,
          'settings': {
            'slidesToShow': 4,
            'slidesToScroll': 1
          }
        }]

      });


      var $next = $('.js-sub-slider-next');
      var $prev = $('.js-sub-slider-previous');

      $next.on('click', function (e) {
          e.preventDefault();
          _.subSlider.slick('next');
        });

      $prev.on('click', function (e) {
        e.preventDefault();
        _.subSlider.slick('prev');
      });
    }

    if (this.singleSlider.length) {
      this.singleSlider.slick({
        'dots': false,
        'buttons': false,
        'arrows': false,
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'asNavFor': '.js-slider-three'
      });
    }

    if (this.threeSlider.length) {
      this.threeSlider.slick({
        'asNavFor': '.js-slider-single',
        'dots': false,
        'buttons': false,
        'arrows': false,
        'slidesToShow': 3,
        'slidesToScroll': 1,
        'focusOnSelect': true,
      });

      // Add border on page load
      $('.js-slider-three').find('.slick-active img').eq(0).css('border', '3px solid orange');

      //After change, remove outline from all and place on first again
      $('.js-slider-three').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.js-slider-three').find('.slick-active img').css('border', 'none');
        $('.js-slider-three').find('.slick-active img').eq(0).css('border', '3px solid orange');
      });

    }

    $(document).foundation();
  }
};

module.exports = SliderController;
